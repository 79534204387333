import { AuthPopupContentTypeEnum } from "dev/components/auth/AuthPopup";
import { ContentManagerTargetType } from "dev/components/contentManager";
import Login from "dev/components/login/Login";
import Registration from "dev/components/registration/Registration";
import { TranslationContext } from "providers/TranslationProvider";
import React, { memo, useContext } from "react";
import contentManagerEventDispatcher from "dev/components/contentManager/contentManagerDispatcher";

type AuthPopupContentType = AuthPopupContentTypeEnum.LOGIN | AuthPopupContentTypeEnum.REGISTRATION;

interface IPropTypes {
  activeContentType: AuthPopupContentType;
  setActiveContentType: CallbackFunction;
  formInvalid: boolean;
  setFormInvalid: CallbackFunction;
}

const AuthPopupTemplate: React.FunctionComponent<IPropTypes> = ({
  activeContentType,
  setActiveContentType,
  formInvalid,
  setFormInvalid
}) => {
  const trans = useContext(TranslationContext);

  const handleSetActiveContentType = (activeContentType: AuthPopupContentType) => () => {
    setActiveContentType(activeContentType);
  };

  const handleSetFormInvalid = (formInvalid: boolean) => () => {
    setFormInvalid(formInvalid);
  };

  const handleSuccess = () => {
    setFormInvalid(false);
    contentManagerEventDispatcher.dispatchEvent("hideAll");
  };

  return (
    <div className={`entrance-popup-bc ${activeContentType === AuthPopupContentTypeEnum.REGISTRATION ? "register" : "sign-in"}`}>
      <div className="e-p-content-holder-bc">
        <div className="e-p-content-bc">
          <div className="e-p-header-bc">
            <div className="popup-t-logo-w-bc">
              <img className="hdr-logo-bc" src="/logo.png" alt="" />
            </div>
            <div className="e-p-sections-bc">
              {activeContentType === AuthPopupContentTypeEnum.REGISTRATION && (
                <div className={`e-p-section-item-bc`}>
                  <span className="e-p-section-title-bc" onClick={handleSetActiveContentType(AuthPopupContentTypeEnum.LOGIN)}>
                    {trans("sign in")}
                  </span>
                </div>
              )}
              {activeContentType === AuthPopupContentTypeEnum.LOGIN && (
                <div className={`e-p-section-item-bc`}>
                  <span className="e-p-section-title-bc" onClick={handleSetActiveContentType(AuthPopupContentTypeEnum.REGISTRATION)}>
                    {trans("register")}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className="e-p-body-bc">
            {activeContentType === AuthPopupContentTypeEnum.REGISTRATION && (
              <Registration
                onFailure={handleSetFormInvalid(true)}
                onSucceed={handleSuccess}
                contentManagerTargetType={ContentManagerTargetType.POPUP}
              />
            )}
            {activeContentType === AuthPopupContentTypeEnum.LOGIN && (
              <Login
                onFailure={handleSetFormInvalid(true)}
                onSucceed={handleSuccess}
                onRegisterClick={handleSetActiveContentType(AuthPopupContentTypeEnum.REGISTRATION)}
                contentManagerTargetType={ContentManagerTargetType.POPUP}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(AuthPopupTemplate);
