import React, { memo, useCallback, useContext, useEffect, useState } from "react";
import Config from "configs";
import { RootEventDispatcher } from "helpers/EventDispatcher";
import { UserContextState } from "providers/UserProvider";
import { useHistory, useLocation } from "react-router";
import { BetslipDispatcher } from "providers/BetslipProvider";
import EventType from "constants/eventType";

interface IPropTypes {
  hideMenuTab?: boolean;
}
const TabNavigation: React.FunctionComponent<IPropTypes> = ({ hideMenuTab = false }) => {
  const openNavMenu = useCallback(() => {
    RootEventDispatcher.dispatchEvent(EventType.OPEN_NAVIGATION_MENU);
  }, []);

  const { tabNavigation } = Config.app;

  const location = useLocation();
  const history = useHistory();
  const pageKey = location.pathname.split("/")[1];
  const { isLoggedIn } = useContext(UserContextState);

  const [tabNavigationItems, setTabNavigationItems] = useState<ITabNavigationItem[]>([]);

  useEffect(() => {
    setTabNavigationItems(tabNavigation[pageKey] || tabNavigation["default"] || []);
  }, [pageKey, tabNavigation]);

  useEffect(() => {
    return BetslipDispatcher.addEventListener("countChanged", (count: number) => {
      setTabNavigationItems(items =>
        items.map(item => {
          if (item.id === "tab-betslip") {
            return {
              ...item,
              badge: count > 0 ? count.toString() : ""
            };
          }
          return item;
        })
      );
    });
  }, []);

  const handleItemClick = useCallback(
    (to: string = "", actions: ITabNavigationItemAction[] = []) => {
      if (to && location.pathname !== to) {
        history.push(to);
      }
      if (actions.length) {
        actions.forEach(action => RootEventDispatcher.dispatchEvent(action.type, action.data));
      }
    },
    [history, location.pathname]
  );

  if (!tabNavigationItems) {
    return null;
  }

  return (
    <div className="tab-navigation-w-bc">
      {tabNavigationItems.map((item, index) => {
        if (item.permissions !== null && item.permissions.loggedIn !== isLoggedIn) {
          return null;
        }

        return (
          <div
            key={index}
            className={`tab-nav-item-bc ${item.to && location.pathname.includes(item.to) ? "active" : ""}`}
            onClick={() => handleItemClick(item.to, item.actions)}
          >
            {item.badge && <span className="count-v-bc">{item.badge}</span>}
            <span className={`tab-nav-icon-bc ${item.className}`} />
            <p className="tab-nav-title-bc">{item.displayName}</p>
          </div>
        );
      })}

      {!hideMenuTab && (
        <div className="tab-nav-item-bc" onClick={openNavMenu}>
          <span className="tab-nav-icon-bc bc-i-apps" />
          <p className="tab-nav-title-bc">Menu</p>
        </div>
      )}
    </div>
  );
};

export default memo(TabNavigation);
