import { IContentTemplate } from "dev/components/contentContainer/ContentContainer";
import GlobalPopup from "dev/components/globalPopup/GlobalPopup";
import React, { memo, useContext } from "react";
import Routes from "Routes";
import "dev/components/contentContainer/Content.scss";
import Popup from "dev/components/popup/Popup";
import Button from "dev/components/button/Button";
import { UserContextState } from "providers/UserProvider";
import { TranslationContext } from "providers/TranslationProvider";
import Loader from "dev/components/loader/Loader";
import Logout from "dev/components/login/Logout";
import TabNavigation from "skins/_global/mobile/components/tabNavigation/TabNavigation";
import { Link } from "react-router-dom";

const ContentTemplate = ({
  hostClassNames,
  isSelected,
  hideContent,
  contentOverlayRef,
  navLinks,
  openRegistrationForm,
  openLoginForm
}: IContentTemplate) => {
  const { isLoggedIn, loginInProgress, profile } = useContext(UserContextState);
  const trans = useContext(TranslationContext);

  return (
    <>
      <div className="layout-content-holder-bc">
        <div className="bet-on-lineup-layout is-mobile">
          <div className="bet-on-lineups-sign-in">
            <Link className="bet-on-lineup-logo" to={"/"}>
              <img src="/logo.png" alt="" />
            </Link>
            {loginInProgress ? (
              <div className="lineup-header-loader">
                <Loader size={"small"} />
              </div>
            ) : !isLoggedIn ? (
              <div className="bet-on-lineup-buttons">
                <div className="bet-on-lineup-buttons-inner">
                  <Button className="bet-on-lineup-button sign-in-mobile" onClick={openLoginForm}>
                    {trans("sign in")}
                  </Button>
                  <Button className="bet-on-lineup-button register-mobile" onClick={openRegistrationForm}>
                    {trans("register")}
                  </Button>
                </div>
              </div>
            ) : (
              <>
                <Logout>
                  {logoutUser => (
                    <div>
                      <span className="bet-on-lineup-money">
                        {profile.calculatedBalanceRounded} {profile.currency_name}
                      </span>
                      <Button className="bet-on-lineup-button register" onClick={logoutUser}>
                        {trans("Logout")}
                      </Button>
                    </div>
                  )}
                </Logout>
              </>
            )}
          </div>
          <Routes />
        </div>
      </div>
      <div className="layout-navigation-holder-bc bet-on-lineup-navigation">
        <TabNavigation hideMenuTab={true} />
      </div>
      <GlobalPopup />
      <Popup />
    </>
  );
};

export default memo(ContentTemplate);
